.cookie-disclaimer {
    width: 100%;
    min-width: 100%;
    text-align: center;

    &.cookie-disclaimer-anchor {
        left: unset;
        right: unset;
        transform: unset;
        padding-bottom: 0;
        bottom: 0;
    }

    > div {
        border-radius: 0;
        display: flex;
        flex-direction: row;

        @media (max-width: 600px) {
            flex-direction: column;
            > div:last-of-type {
                margin-right: auto;
                padding-left: 0;
            }
        }

        > div:first-of-type {
            flex: 1;
        }
    }
}
