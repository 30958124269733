.image-root {
    // overflow: hidden;
    // position: relative;
    width: 100%;
    height: 100%;
    // background-size: 100% 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: inherit;
    position: relative;

    .image {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &.image-preview {
            filter: blur(2vw);
            transform: scale(1.05);
        }

        &.image-reveal {
            will-change: transform, opacity;
            animation: progressiveReveal 1s ease-out;
        }
    }
}

@keyframes progressiveReveal {
    0% {
        transform: scale(1.05);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
